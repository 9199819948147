import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumbs from "../components/breadcrumbs";
import ReactMarkdownWithHtml from "react-markdown";
import VideoEmbbed from "../components/video-embbed";
import SocialShare from "../components/socialShare";

function ArticleTemplate({ pageContext: { article } }) {
    const ldJson = [];
    const breadcrumbs = [];
    breadcrumbs.push({ name: "Blog", link: `/blog` });
    breadcrumbs.push({ name: article.Title, link: `/blog/${article.Slug}` });

    const fullUrl =  `https://liderancaegestao.online/blog/${article.Slug}`;

    const breadcrumbsLd = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": breadcrumbs.map((item, index) => {
            return {
                "@type": "ListItem",
                "position": index + 1,
                "name": item.name,
                "item": `https://liderancaegestao.online${item.link}`
            }
        })
    };
    ldJson.push(breadcrumbsLd);

    return (
        <Layout>
            <SEO title={article.Title} ldJson={ldJson} description={article.MetaDescription} keywords={article.MetaKeywords} />
            <Breadcrumbs items={breadcrumbs} />
            <h1>{article.Title}</h1>
            {article.VideoPosition == "TOP" &&
                <VideoEmbbed videoLink={article.VideoUrl} videoTitle={null} />
            }
            <div className="flex flex-col md:flex-row pb-14">
                <div className="w-full md:w-12/12 mt-4 md:mt-0 md:ml-1">
                    <p className="leading-normal pt-2">
                        <ReactMarkdownWithHtml children={article.Description} />
                    </p>
                    <SocialShare url={fullUrl} title={article.Title} contentType="product" />
                </div>
            </div>
            {article.VideoPosition == "BOTTOM" &&
                <VideoEmbbed videoLink={article.VideoUrl} videoTitle={null} />
            }
        </Layout>
    )
}

export default ArticleTemplate