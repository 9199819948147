import React from "react"
import { Link } from "gatsby";

function Breadcrumbs({ items }) {
    if (!items || items.length === 0) {
        return null;
    }
    const lastItemIndex = items.length -1;
    return (
        <nav className="bg-grey-light rounded-md w-full overflow-hidden">
            <ol className="list-reset flex">
                <li><Link to="/" className="text-blue-600 hover:text-blue-700">Home</Link></li>
                {items.map((item, index) => {
                    if(item.link == null || index == lastItemIndex) {
                        return (<><BreadcrumbSpacer /><li className="text-gray-500">{item.name}</li></>);
                    }
                    return (<><BreadcrumbSpacer /><li><Link to={item.link} className="text-blue-600 hover:text-blue-700">{item.name}</Link></li></>);
                })}
            </ol>
        </nav>
    );
}

function BreadcrumbSpacer() {
    return (<li><span className="text-gray-500 mx-2">{">"}</span></li>);
}
export default Breadcrumbs;