import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback } from "react";

export default function SocialShare({url, title, contentType = "URL"}) {
    
    const trackShareClick = useCallback((socialName, itemId) => {
        if (!window.gtag)
          return;
    
        window.gtag('event', 'share', {
          "method": socialName,
          "content_type": contentType,
          "item_id": itemId,
        });
      }, []);

    return (
        <p className="pas">COMPARTILHE<br />
            <a onClick={() => trackShareClick("facebook", url)} className="pr-1 pasb bt-share-face" href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" rel="noopener external nofollow" aria-label={`Compartilhe no Facebook: ${title}`}>
                <StaticImage src="../images/facebook-18w50.webp" alt="Compartilhe no Facebook" height={25} width={25} />
            </a>
            <a onClick={() => trackShareClick("LinkedIn", url)} className="pr-1 pasb bt-share-linkedin" href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`} target="_blank" rel="noopener external nofollow" aria-label={`Compartilhe no LinkedIn: ${title}`}>
                <StaticImage src="../images/linkedin-19w50.webp" alt="Compartilhe no LinkedIn" height={25} width={25} />
            </a>
            <a onClick={() => trackShareClick("WhatsApp", url)} className="pr-1 pasb bt-share-whatsapp" href={`https://api.whatsapp.com/send?text=${url}`} target="_blank" rel="noopener external nofollow" aria-label={`Compartilhe ${title} no WhatsApp`}>
                <StaticImage src="../images/whatsapp-20w50.webp" alt="Compartilhe no WhatsApp" height={25} width={25} />
            </a>
            <a onClick={() => trackShareClick("Twitter", url)} className="pr-1 pasb bt-share-twitter" href={`https://twitter.com/intent/tweet?text=${title}&url=${url}`} target="_blank" rel="noopener external nofollow" aria-label={`Compartilhe ${title} no Twitter`}>
                <StaticImage src="../images/twitter.png" alt="Compartilhe no WhatsApp" height={25} width={25} />
            </a>
        </p>
    );
}