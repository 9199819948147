import React from "react";

function VideoEmbbed({videoLink, videoTitle }) {
  if (!videoLink)
    return null;

  return (
    <div className="pt-5" >
      <h2>{videoTitle}</h2>
      <hr />
      <div className="absolute top-0 left-0 w-full h-full relative overflow-hidden max-w-full w-full aspect-w-16 aspect-h-9" style={{ paddingBottom: "56.25%" }}>
        <iframe src={videoLink} className="absolute top-0 left-0 w-full h-full" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  );
}

export default VideoEmbbed;